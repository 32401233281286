import React, {useState} from 'react';
import * as styles from './Service.module.scss';
import classNames from 'classnames';
import {Typography} from "../Typography";
import {ServiceIcon} from "../ServiceIcon";
import {LangLink} from "../LangLink";
import {componentRef} from "../../functions/componentRef";

/* eslint-disable jsx-a11y/mouse-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
export function Service({
                            icon,
                            layout = 'horizontal',
                            slug,
                            iconSize = "small",
                            link,
                            name,
                            children,
                            className,
                            appearance,
                            ...otherProps
                        }) {
    const [isHovered, setHovered] = useState(false);
    const isLink = !!link;
    const classes = classNames(
        !isLink && className,
        styles.root,
        styles[`layout_${layout}`]
    );

    const content = <article className={classes}
                             data-appearance={appearance}
                             {...componentRef.subcomponent('service-article')}
                             onMouseOver={() => setHovered(true)}
                             onMouseOut={() => setHovered(false)}
                             {...otherProps}
    >
        <div className={styles.iconWrapper}>
            {icon ? <ServiceIcon hovered={isHovered} icon={icon} size={iconSize} className={styles.icon}/> : undefined}
        </div>
        <div className={styles.contentWrapper}>
            <h1 className={styles.title}>{name}</h1>
            <Typography className={styles.content}>
                {children}
            </Typography>
        </div>
    </article>;

    return isLink ?
        <LangLink to={`/service/${slug}`} className={classNames(className, styles.link)}>{content}</LangLink> : content;
}
