import React from 'react';
import * as styles from './CaseStudyHero.module.scss';
import classNames from 'classnames';
import {Breadcrumbs} from "../Breadcrumbs";
import {useIntl} from "gatsby-plugin-react-intl";
import {MarkdownContent} from "../MarkdownContent";
import {Typography} from "../Typography";
import {Metric} from "../Metric";
import {GatsbyImage} from "gatsby-plugin-image";
import {SVGSupportImg} from "../SVGSupportImg";
import {TypographyV2} from "../TypographyV2";
import {MarkdownRenderer} from "../MarkdownRenderer";

export function CaseStudyHero({title, metrics, color, image, services, intro, logo, className, ...otherProps}) {
    const intl = useIntl();

    const hasImage = image && image.localFile;
    const hasMetrics = metrics && metrics.length
    return (
        <section className={classNames(styles.root, className)}>
            {hasImage ?
                <div className={styles.imageWrapper}>
                    <div className={styles.imageColor} data-color={color}>
                        <GatsbyImage
                            className={styles.image}
                            image={image.localFile.childImageSharp.gatsbyImageData}
                            alt={"Case study"}/>
                    </div>
                </div> : undefined
            }
            <div className={styles.content}>
                <Breadcrumbs className={styles.breadcrumbs}>
                    <Breadcrumbs.Item to="/">{intl.formatMessage({id: 'menu.home'})}</Breadcrumbs.Item>
                    <Breadcrumbs.Item>{intl.formatMessage({id: 'menu.caseStudy'})}</Breadcrumbs.Item>
                </Breadcrumbs>

                <SVGSupportImg {...logo} alt={"Case study"}/>
                <TypographyV2 tag={"h1"} variant="h2" className={styles.title}>{title}</TypographyV2>
                <MarkdownRenderer content={intro} className={styles.intro}/>

                <div className={styles.services}>
                    {services.map((x, index) => {
                        return <div key={index} className={styles.service}>
                            {x.name}
                        </div>;
                    })}
                </div>
            </div>

            {hasMetrics ?
                <div className={styles.metrics}>
                    {metrics.map((x, i) => {
                        return <Metric title={x.name} key={i} alignedBottom>{x.value}</Metric>;
                    })}
                </div> : undefined
            }

        </section>
    );
}
