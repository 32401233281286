import * as styles from './ServiceIcon.module.scss';
import React from 'react';
import classNames from 'classnames';

const ICONS = {
    bi: require('../../assets/service/bi.svg'),
    bpm: require('../../assets/service/bpm.svg'),
    dt: require('../../assets/service/dt.svg'),
    rpm: require('../../assets/service/rpm.svg'),
    spm: require('../../assets/service/spm.svg'),
    tcm: require('../../assets/service/tcm.svg')
};

export function ServiceIcon({icon, className, hovered, size = 'normal', ...otherProps}) {
    return <div className={classNames(styles.root, className, hovered && styles.hovered)}
                data-size={size}
                {...otherProps}>
        <img src={ICONS[icon].default} alt="" className={styles.image}/>
    </div>
}
