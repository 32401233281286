// extracted by mini-css-extract-plugin
export var breadcrumbs = "CaseStudyHero-module--breadcrumbs--dbcd4";
export var content = "CaseStudyHero-module--content--bc00a";
export var image = "CaseStudyHero-module--image--8872b";
export var imageColor = "CaseStudyHero-module--imageColor--4241f";
export var imageWrapper = "CaseStudyHero-module--imageWrapper--be683";
export var metrics = "CaseStudyHero-module--metrics--1cdf3";
export var root = "CaseStudyHero-module--root--a769c";
export var service = "CaseStudyHero-module--service--3f3f4";
export var services = "CaseStudyHero-module--services--97e61";
export var title = "CaseStudyHero-module--title--0000c";