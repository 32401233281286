import React from "react"
import {Layout} from "../components/Layout";
import {PageMainColumnWrapper} from "../components/PageMainColumnWrapper";
import {ContactPersonBlock} from "../components/ContactPersonBlock";
import {TestimonialHero} from "../components/TestimonialHero";
import {graphql} from "gatsby";
import {MarkdownContent} from "../components/MarkdownContent";
import {rwdImages} from "../functions/rwdImages";
import {BackgroundLine} from "../components/BackgroundLine";
import {injectIntl} from 'gatsby-plugin-react-intl';
import {CaseStudyHero} from "../components/CaseStudyHero";
import * as styles from './caseStudy.module.scss';
import {CaseStudyUsedServices} from "../components/CaseStudyUsedServices";
import {createSeoData} from "../functions/createSeoData";
import {MarkdownRenderer} from "../components/MarkdownRenderer";

export const query = graphql`query CaseStudy($locale: String!, $slug: String!) {
    caseStudy: strapiCaseStudy(slug: {eq: $slug}, locale: {eq: $locale}) {
        title
        color
        intro {
            data {
                childMarkdownRemark {
                    html
                }
            }
        }
        image {
            localFile {
                childImageSharp {
                    gatsbyImageData(
                        width: 330
                        height: 600
                        backgroundColor: "transparent"
                        placeholder: NONE
                        transformOptions: {fit: COVER}
                        layout: FIXED
                    )
                }
            }
        }
        preContent {
            data {
                childMarkdownRemark {
                    html
                }
            }
        }
        postContent {
            data {
                childMarkdownRemark {
                    html
                }
            }
        }
        servicesDescription {
            data {
                childMarkdownRemark {
                    html
                }
            }
        }
        services {
            __typename
            ... on STRAPI_SERVICE_V_3 {
                slug
                name
            }
        }
        metricsComponents {
            name
            value
        }
        logo {
            localFile {
                url
                childImageSharp {
                    gatsbyImageData(
                        width: 200
                        height: 100
                        backgroundColor: "transparent"
                        placeholder: NONE
                        transformOptions: {fit: CONTAIN}
                        layout: FIXED
                    )
                }
            }
        }
        contactPersonTitle
        contactPersonDescription {
            data {
                childMarkdownRemark {
                    html
                }
            }
        }
        heroReference {
            intro
            content
            personName
            personPosition
            personCountry
            heroImage {
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 1106
                            height: 622
                            placeholder: NONE
                            transformOptions: {fit: COVER, cropFocus: WEST}
                            layout: FIXED
                        )
                    }
                }
            }
            mobileHeroImage: heroImage {
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 600
                            height: 260
                            placeholder: NONE
                            transformOptions: {cropFocus: EAST}
                            layout: FIXED
                        )
                    }
                }
            }
            logo {
                localFile {
                    url
                    childImageSharp {
                        gatsbyImageData(
                            width: 150
                            height: 80
                            backgroundColor: "transparent"
                            placeholder: NONE
                            transformOptions: {fit: CONTAIN}
                            layout: FIXED
                        )
                    }
                }
            }
        }
        contactPerson {
            name
            position
            email
            phone
            bigImage {
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 680
                            height: 1000
                            placeholder: NONE
                            transformOptions: {cropFocus: NORTH}
                            layout: FIXED
                        )
                    }
                }
            }
            mobileBigImage: bigImage {
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 300
                            height: 400
                            placeholder: NONE
                            transformOptions: {cropFocus: NORTH}
                            layout: FIXED
                        )
                    }
                }
            }
        }
        seo {
            ...Seo
        }
    }
}
`

function renderContactPerson(contactPerson, title, description) {
    if (!contactPerson) {
        return;
    }
    return (
        <ContactPersonBlock
            title={title}
            image={contactPerson.bigImage.localFile}
            mobileImage={contactPerson.mobileBigImage.localFile}
            person={{
                phone: contactPerson.phone,
                email: contactPerson.email,
                name: contactPerson.name,
                position: contactPerson.position
            }}
        >
            <MarkdownContent content={description}/>
        </ContactPersonBlock>
    );
}

function renderHeroReference(heroReference) {
    if (!heroReference) {
        return;
    }

    return (
        <TestimonialHero className={styles.heroReference}
                         image={{
                             fixed: rwdImages(
                                 heroReference.heroImage.localFile.childImageSharp.gatsbyImageData,
                                 heroReference.mobileHeroImage.localFile.childImageSharp.gatsbyImageData,
                             )
                         }}
                         intro={heroReference.intro.intro}
                         description={heroReference.content.content}
                         logo={heroReference.logo.localFile}
                         person={{
                             name: heroReference.personName,
                             position: heroReference.personPosition,
                             country: heroReference.personCountry
                         }}/>
    );
}

function CaseStudyPage({data, intl, pageContext}) {
    return <Layout services={pageContext.services}
                   oldServices={pageContext.oldServices}
                   linkableSubServices={pageContext.linkableSubServices}
                   title={intl.formatMessage({id: 'menu.caseStudy'})}
                   jobsCount={pageContext.jobsCount}
                   defaultHrefLang="en"
                   seo={createSeoData(data.caseStudy.seo)}
                   companyData={pageContext.companyData}
                   background={<>
                       <BackgroundLine mobileOn={false}/>
                   </>}
                   lang={pageContext.lang}>
        <PageMainColumnWrapper className={styles.mainWrapper}>
            <CaseStudyHero
                title={data.caseStudy.title}
                intro={data.caseStudy.intro.data}
                image={data.caseStudy.image}
                color={data.caseStudy.color}
                services={data.caseStudy.services}
                metrics={data.caseStudy.metricsComponents}
                logo={data.caseStudy.logo.localFile}
            />

            {renderHeroReference(data.caseStudy.heroReference)}

            <div className={styles.content}>
                <MarkdownRenderer reduceHeaderLevelBy={1} reduceHeaderVariantBy={2}
                                  isDocumentTypography={true}
                                  content={data.caseStudy.preContent.data}/>
                <CaseStudyUsedServices
                    content={data.caseStudy.servicesDescription}
                    services={data.caseStudy.services}
                />
                <MarkdownRenderer reduceHeaderLevelBy={1} reduceHeaderVariantBy={2}
                                  isDocumentTypography={true}
                                  content={data.caseStudy.postContent.data}/>
            </div>
        </PageMainColumnWrapper>

        {renderContactPerson(
            data.caseStudy.contactPerson,
            data.caseStudy.contactPersonTitle,
            data.caseStudy.contactPersonDescription
        )}
    </Layout>
}

export default injectIntl(CaseStudyPage);
